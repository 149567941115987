import { CarModel } from "@/api";
import { transltePrices } from "@/common/helper";
import styled from "@emotion/styled";
import { Box, Card, CardContent, Typography } from "@mui/material";
import Image from "next/image";
import { InfoTag } from "./InfoTag";

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
`;

const FixImage = styled(Image)`
  @media (max-width: 768px) {
    aspect-ratio: 328/146;
  }
`;

type CardCarProps = {
  car: CarModel;
};

const Seperator = styled.div`
  width: 95%;
  margin: 10px;
  border: 1px solid rgba(230, 230, 230, 1);
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const CardCar = ({ car }: CardCarProps) => {
  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {car?.images && (
        <Box sx={{ aspectRatio: "250/146", position: "relative" }}>
          <FixImage
            src={car?.images[0]?.imageUrl}
            alt={car?.images[0]?.alt || ""}
            style={{ objectFit: "cover" }}
            layout="fill"
          />
        </Box>
      )}

      <CardContent
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Typography
          gutterBottom
          fontWeight={300}
          fontSize={14}
          sx={{ textAlign: "right" }}
          variant="h3"
        >
          {car?.brand.name} {car?.modelName} {car?.finishingLevelName}
        </Typography>
        <InfoWrapper>
          {car?.tags?.map((tag, i) => <InfoTag key={i} info={tag} />)}
        </InfoWrapper>
        <Box sx={{ marginTop: "auto" }}>
          <Seperator />
          <PriceWrapper>
            <Typography fontWeight={400} fontSize={12} component="div">
              מחיר
            </Typography>
            <Typography fontWeight={400} fontSize={16} component="div">
              {`${transltePrices(String(car?.price))} ₪`}
            </Typography>
          </PriceWrapper>
        </Box>
      </CardContent>
    </Card>
  );
};
